<template>
  <div>
    <el-skeleton
      class="skeleton"
      :rows="10"
      animated
      v-if="artiaclInfoLoading == true"
    />
  </div>
  <div
    class="body_height"
    v-if="artiaclInfoLoading == false"
  >
    <el-empty
      description="相关文章正在快速更新中，敬请期待..."
      v-if="noArticleData"
    />
    <div
      v-for="articleItem in articleInfo"
      :key="articleItem.id"
      class="relativeClass"
    >
      <router-link :to="{name:'Article', params: {id:articleItem.id}}">

        <div class="articleList">
          <div
            v-if="articleItem.is_top == 1"
            class="top"
          ></div>
          <div class="articleUp">
            <img
              v-if="articleItem.is_top == 1"
              class="upIcon"
              src="../assets/up.png"
            >
            <!-- 标题 -->
            <p class="articleTitle">{{ articleItem.title }}</p>
            <div class="titleBottom">
              <div>
                <!-- 作者 -->
                <span class="titleBottomIcon">作者：{{ articleItem.author }}</span>
                <!-- 分类 -->
                <span class="titleBottomIcon">分类：{{ articleItem.category_name }}</span>
              </div>
              <div>
                <span class="articleCreateTime"><i class="fa fa-calendar"></i>&nbsp;&nbsp;{{
                      articleItem.create_time
                    }}</span>
              </div>
            </div>
          </div>
          <!-- 分割线 -->
          <hr class="articleHr" />
          <div class="articleBottom">
            <div class="coverImg">
              <img
                :src="photoUrl(articleItem.cover_photo)"
                class="coverPhoto"
              />
            </div>

            <div class="articleBottomText">
              <p class="subtitle">&nbsp;&nbsp;&nbsp;&nbsp;{{ articleItem.subtitle }}
              </p>
              <div class="subtitleBottom">
                <div class="articleTag">
                  <span
                    v-for="tagItem in articleItem.tag_name"
                    :key="tagItem.id"
                    class="tag"
                  >#{{ tagItem.tag_name }}</span>
                </div>
                <div>
                  <!-- 阅读数 -->
                  <span class="subtitleBottomIcon"><i class="fa fa-eye"></i>&nbsp;{{articleItem.read_nums}}</span>
                  <!-- 评论数 -->
                  <span class="subtitleBottomIcon"><i
                      class="fa fa-commenting-o"></i>&nbsp;{{articleItem.comment_nums}}</span>
                  <!-- 点赞数 -->
                  <span class="subtitleBottomIcon"><i
                      class="fa fa-thumbs-o-up"></i>&nbsp;{{articleItem.like_nums}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
  <div class="page">
    <el-pagination
      layout="prev, pager, next"
      :total="articleTotal"
      :page-size="pageSize"
      :current-page="page"
      @current-change="changePage"
      :background="true"
      :hide-on-single-page="true"
    />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
// import { onMounted, ref } from '_vue@3.2.31@vue'
import { SELECTARTICLES } from '../api/api'
export default {
  props: {
    from: {
      Type: Object
    }
  },
  setup(props) {
    onMounted(() => {
      // selectArticles()
    })
    // 文章信息
    const articleInfo = ref([])
    // 文章总条数
    const articleTotal = ref()
    // 当前页数
    const page = ref(1)
    // 每页展示条数
    const pageSize = ref(7)
    // 修改分页
    const changePage = (current_change) => {
      page.value = current_change
      selectArticles()
    }
    // 文章列表等待状态（鱼骨）
    const artiaclInfoLoading = ref(false)
    // 筛选文章数据为空时，展示空状态
    const noArticleData = ref(false)
    // 查询文章信息
    const selectArticles = () => {
      artiaclInfoLoading.value = true
      SELECTARTICLES(
        Object.assign(
          { page: page.value, pageSize: pageSize.value },
          props.from
        )
      ).then((response) => {
        if (response.code === 0) {
          articleInfo.value = response.data.data
          articleTotal.value = response.data.total
          artiaclInfoLoading.value = false
          if (response.data.total === 0) {
            noArticleData.value = true
          } else {
            noArticleData.value = false
          }
        }
      })
    }
    // 处理图片展示路径
    const photoUrl = (url) => {
      return (
        'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/' +
        JSON.parse(url)[0]
      )
    }
    return {
      articleInfo,
      articleTotal,
      page,
      pageSize,
      changePage,
      artiaclInfoLoading,
      photoUrl,
      selectArticles,
      noArticleData
    }
  }
}
</script>

<style scoped>
.body_height {
  min-height: calc(100vh - 528px);
}
/* 鱼骨  */
.skeleton {
  background-color: #fff;
  margin-top: 8px;
  padding: 8px;
  width: 100%;
}

/* 文章展示 */
.articleList {
  margin: 8px 0px;
  background-color: #fff;
  position: relative;
}

.top {
  content: '';
  height: 0px;
  width: 0px;
  border-width: 20px;
  border-style: solid;
  border-top-color: #2f4858;
  border-right-color: #2f4858;
  border-left-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  right: 0px;
}

.articleUp {
  height: 60px;
  padding: 8px 16px 4px;
}

.articleUp .upIcon {
  position: absolute;
  top: 4px;
  right: 4px;
  height: 16px;
}

.articleTitle {
  padding-bottom: 4px;
  font-weight: bold;
  font-size: 1.1rem;
}

.titleBottom,
.subtitleBottom {
  display: flex;
  justify-content: space-between;
}

.articleTag {
  padding-left: 8px;
}

.titleBottomIcon,
.tag {
  font-size: 12.8px;
  padding-right: 24px;
  color: #999999;
}

.articleHr {
  margin: 4px 16px;
  background-color: var(--subject-color);
  border: none;
  height: 1px;
}

.articleBottom {
  padding: 4px 16px 8px 16px;
  display: flex;
  /* flex-direction: row; */
}

.coverImg {
  height: 120px;
  width: 180px;
  flex-shrink: 0;
  overflow: hidden;
}

.coverPhoto {
  height: 100%;
  width: 100%;
  transition: all 0.5s;
}

.coverPhoto:hover {
  transform: scale(1.3);
}

.articleBottomText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.subtitle {
  margin: 0 0 8px 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.subtitleBottomIcon,
.articleCreateTime {
  font-size: 12.8px;
  padding-left: 24px;
  color: #999999;
}

.page {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 560px) {
  .articleTag {
    display: none;
  }
  .subtitleBottom {
    justify-content: flex-end;
  }
}
</style>
